const image1 = document.getElementById("image1")
const image2 = document.getElementById("image2")
const env = window.location.href
const imageApiUrl = env + "umbraco/api/HomeImage/GetImage"

async function fetchImageUrls() {
    try {
        const response = await fetch(imageApiUrl) // Replace with the actual API URL
        if (!response.ok) {
            throw new Error("Failed to fetch image URLs from the API.")
        }
        const data = await response.json()
        return data
    } catch (error) {
        // console.error(error);
        return []
    }
}

// let imageUrls = ["https://images.unsplash.com/photo-1604537466158-719b1972feb8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxzZWFyY2h8MXx8bW91bnRhaW58ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60", 
// "https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bW91bnRhaW58ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60", 
// "https://images.unsplash.com/photo-1519681393784-d120267933ba?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bW91bnRhaW58ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
// "https://images.unsplash.com/photo-1549880181-56a44cf4a9a5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"];

let imageUrls = []

async function updateImage(image) {
    var img1Url = "/" + image1.src.replace(env,'').replace("&format=webp",'');
    var img2Url = "/" + image2.src.replace(env,'').replace("&format=webp",'');
    const urlsToFilter = [img1Url, img2Url];
    const filteredUrls = imageUrls.filter(urls => !urlsToFilter.includes(urls));
    const randomUrl = filteredUrls[Math.floor(Math.random() * filteredUrls.length)];
    image.style.opacity = 0;
    setTimeout(() =>{
        image.src = randomUrl+"&format=webp";
        image.style.opacity = 1;
    }, 650);
}

async function updateImage1() {
    await updateImage(image1)
    setTimeout(updateImage2, 10000)
}

async function updateImage2() {
    await updateImage(image2)
    setTimeout(updateImage1, 10000)
}

async function fetchAndDisplayImages() {
    imageUrls = await fetchImageUrls()
    if(imageUrls.length >2 ){
        updateImage1()
    }
}

if ((image1 && image2)) {
    setTimeout(fetchAndDisplayImages, 10000);     
}

  

 

  
