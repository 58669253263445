import { slideToggle } from "../utils"

window.onload = () => {
    const navOverlay = document.getElementById("nav-overlay")
    const navLinks = document.querySelectorAll(".nav-link")
    const mobileNav = document.getElementById("mobile-menu")
    const subNavs = document.querySelectorAll(".nav-subnav")
    const nav = document.getElementById("nav")
    const mobileSublinks = document.querySelectorAll(".nav-sublink")
    let isClicked = false

    if (navLinks.length > 0) {
        mobileSublinks.forEach((link) => {
            if (link.nextElementSibling) {
                link.addEventListener("click", () => {
                    link.classList.toggle("after:rotate-0")
                    // link.nextElementSibling.classList.toggle("hidden");
                    link.nextElementSibling.classList.toggle("flex");
                })
                mobileSublinks.forEach((link) => {
                    if (link.childElementCount > 0) {
                        link.addEventListener("click", () => {
                            link.classList.toggle("after:rotate-360");
                            link.nextElementSibling.classList.toggle("hidden");
                            link.nextElementSibling.classList.toggle("flex");
                        })
                    }
                })
            }
        })
        navLinks.forEach((link) => {
            if (link.nextElementSibling) {
                const linkSubnav = link.nextElementSibling
                link.addEventListener("click", () => {
                    link.classList.toggle("after:rotate-180");
                    navOverlay.classList.add("hidden")
                    if (linkSubnav.classList.contains("hidden")) {
                        subNavs.forEach((subNav) => {
                            subNav.classList.add("hidden")
                            subNav.classList.remove("flex")
                        })
                    }
                    linkSubnav.classList.toggle("hidden")
                    linkSubnav.classList.toggle("flex")

                    if (window.innerWidth > 768) {
                        subNavs.forEach((subNav) => {
                            if (subNav.classList.contains("flex")) {
                                navOverlay.classList.remove("hidden")
                            }
                        })
                    }
                })
            }
        })
        mobileSublinks.forEach((link) => {
            if (link.nextElementSibling) {
                const linkSubnav = link.nextElementSibling
                link.addEventListener("click", (e) => {
                    linkSubnav.classList.toggle("hidden")
                    linkSubnav.classList.toggle("flex")
                    if (link.href) {
                        if (window.innerWidth < 768) {
                            e.preventDefault()
                            if (!isClicked) {
                                isClicked = true
                            } else {
                                window.location.href = link.href
                            }
                        } else {
                            navOverlay.classList.toggle("hidden")
                        }
                    }
                })
            }
        })

        subNavs.forEach((subNav) => {
            subNav.addEventListener("click", (e) => {
                e.stopPropagation()
            })
        })

        window.addEventListener("scroll", handleScroll)

        mobileNav.addEventListener("click", () => {
            mobileNav.classList.toggle("rotate-90")
            slideToggle(nav, getComputedStyle(nav).height)
            subNavs.forEach((subNav) => {
                subNav.classList.add("hidden")
                subNav.classList.remove("flex")
            })
        })

        if (!navOverlay.classList.contains("hidden")) {
            document.addEventListener("click", (e) => {
                if (e.target == navOverlay) {
                    navOverlay.classList.toggle("hidden")
                    subNavs[0].classList.add("hidden")
                    subNavs[0].classList.remove("flex")
                }
            })
        }

        document.addEventListener("click", (e) => {
            if (e.target == navOverlay) {
                navOverlay.classList.toggle("hidden")
                subNavs.forEach((subNav) => {
                    subNav.classList.add("hidden")
                    subNav.classList.remove("flex")
                })
            }
        })
    }

    function handleScroll() {
        const scrollPosition = window.scrollY
        const scrollThreshold = 300

        if (scrollPosition > scrollThreshold && !navOverlay.classList.contains("hidden")) {
            navOverlay.classList.toggle("hidden")
            subNavs.forEach((subNav) => {
                subNav.classList.add("hidden")
                subNav.classList.remove("flex")
            })
        }
    }
}
