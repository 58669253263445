import Cookies from "js-cookie"

const banner = document.getElementById("banner")
const bannerClose = document.getElementById("close")

if (banner) {
    // const bannerHeight = parseInt(getComputedStyle(banner).height) * -1 + "px";
    // document.documentElement.style.setProperty("--component-height", bannerHeight);
    bannerClose.addEventListener("click", () => {
        // banner.classList.add('animate-fade-up');
        // slideToggle(banner, getComputedStyle(banner).height)
        banner.style.display = "none"
        Cookies.set("notice", "closed", { expires: 7 })
    })

    if (Cookies.get("notice") === "closed") {
        banner.style.display = "none"
    }
    // banner.addEventListener('animationend', () => {
    //     banner.classList.add('hidden');
    // });
}
